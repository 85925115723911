import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DropdownModule, SidebarModule } from '@coreui/angular';
import { IconSetService } from '@coreui/icons-angular';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './services/auth.interceptor';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonService } from "./services/common.service";
import { DataService } from "./services/data.service";
import { EncryptionDecryption } from "./services/encrypt-decrypt";
import { GeoCodeService } from "./services/geo-code.service";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FiltersPipe } from './shared/pipes/filters.pipe';
import { RouterModule } from '@angular/router';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: "toast-top-right",
    }),
    NgxSpinnerModule,
    NgbModule,
    FiltersPipe,
    RouterModule,
    NgMultiSelectDropDownModule.forRoot() 
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    CommonService,
    DataService,
    EncryptionDecryption,
    GeoCodeService,
    DropdownModule,
    SidebarModule,
    IconSetService,
    provideAnimations(),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
